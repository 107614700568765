var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-grow-1 flex-column fill-height",attrs:{"hover":"","outlined":"","flat":"","to":{
    name: 'accountGroup',
    params: {
      accountGroupId: _vm.item.id,
    },
  }}},[_c('v-card-title',{staticClass:"pa-3 pb-0",class:{ 'pb-3': !_vm.item.description }},[_c('v-row',[_c('v-col',{staticClass:"text-h6 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.item.accountGroupName)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,class:item.color,attrs:{"link":"","to":item.route || null},on:{"click":function($event){item.function() || null}}},[_c('v-list-item-icon',[_c('v-icon',{class:item.color},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1)],1)],1)],1),(_vm.item.description)?_c('v-card-text',{staticClass:"pa-3 pt-1"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }