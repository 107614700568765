
























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import AccountGroupCard from "@/settings/components/networks-management/AccountGroupCard.vue";
import NetworkSidebarForm from "@/settings/components/networks-management/NetworkSidebarForm.vue";
import AccountGroupForm from "@/settings/components/networks-management/AccountGroupForm.vue";
import AccountGroupRequestModel from "@/settings/models/networks-management/AccountGroupRequestModel";
import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";

@Component({
  components: {
    AccountGroupCard,
    NetworkSidebarForm,
    AccountGroupForm,
  },
})
export default class AccountsGroupsView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  visible = false;
  localValue = new AccountGroupRequestModel();
  editingAccountGroup: AccountGroupResponseModel | null = null;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.accountsGroupsIsLoading;
  }

  get accountsGroups(): Array<AccountGroupResponseModel> {
    return this.$store.state.networksManagementStore.accountsGroups;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingAccountGroupInProgress;
  }

  get formTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.${
        this.editingAccountGroup ? "editAccountGroup" : "newAccountGroup"
      }`
    );
  }

  get initialNameOfEditedAccountGroup(): string {
    return this.editingAccountGroup?.accountGroupName ?? "";
  }

  @Watch("visible")
  watchVisible(value: boolean) {
    if (!value) {
      this.resetLocalData();
    }
  }

  async handleSavingAccountGroup(closeDialog: () => void) {
    if (this.editingAccountGroup) {
      await this.$store.dispatch("updateAccountGroup", {
        id: this.editingAccountGroup.id,
        payload: this.localValue,
        isForList: true,
      });
    } else {
      this.localValue.networkIntegrationId = this.networkIntegration.id;
      await this.$store.dispatch("createAccountGroup", this.localValue);
    }

    closeDialog();
    this.resetLocalData();
  }

  handleEditingAccountGroup(item: AccountGroupResponseModel) {
    this.localValue = AccountGroupRequestModel.of(item);
    this.editingAccountGroup = cloneDeep(item);
    this.visible = true;
  }

  handleResyncAccountGroup(id: number) {
    this.$store.dispatch("resyncAccountGroup", id);
  }

  handleDeletingAccountGroup(id: number) {
    this.$store.dispatch("deleteAccountGroup", id);
  }

  resetLocalData() {
    this.localValue = new AccountGroupRequestModel();
    this.editingAccountGroup = null;
  }
}
