
























































import { Component, Prop, Vue } from "vue-property-decorator";

import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";

@Component
export default class AccountGroupCard extends Vue {
  @Prop() item!: AccountGroupResponseModel;
  @Prop({ default: 0 }) networkIntegrationId!: number;

  readonly menuItems: Array<Record<string, any>> = [
    {
      function: this.emitEdit,
      label: this.$lang("btn.edit"),
      icon: "mdi-pencil",
    },
    {
      function: this.emitResync,
      label: this.$lang("btn.resync"),
      icon: "mdi-reload",
    },
    {
      function: this.emitDelete,
      label: this.$lang("btn.delete"),
      icon: "mdi-delete",
      color: "error--text",
    },
  ];

  emitEdit() {
    this.$emit("edit", this.item);
  }

  emitResync() {
    this.$emit("resync", this.item.id);
  }

  emitDelete() {
    this.$emit("delete", this.item.id);
  }
}
